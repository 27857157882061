<!-- 文字跑马灯 -->
<template>

  <div style="width: 100%;" ref="getWidth">
    <div class="wrap">
      <div ref="box" class="box">
        <a
            style="font-size: 16px"
            v-for="(item,index) in text"
            @click="showNewsInfo(item)"
            @mouseenter="stopText"
            @mouseleave="startText">{{index + 1}}.{{item.title.substring(0, 10) }}
            <span v-if="item.title.length > 10"> ...</span>
        </a>
      </div>

    </div>
    <b-modal
        id="modal-newsInfo"
        ok-only
        ok-title="确认"
        @ok="updateReaderInf"
        centered
        size="lg"
        :title="newsInfo.title"
        ref="newsModal"
    >
      <b-card>
        <span v-html="newsInfo.content"></span>
      </b-card>
    </b-modal>
  </div>



</template>
<script>

import axios from '@axios'

export default {
  data() {
    return {
      timer: null,     //时间片
      distance: '', // 初始播放距离
      text: '',
      newsInfo: '',
    }
  },
  methods: {
    getNews() {
      let that = this
      axios.post('/api/news/list', {"platform": 1}).then(function (resp) {
        that.text = resp.data.data.list;
        that.startText()
      })
    },
    startText() {
      this.stopText()
      //将文章标题和序号拼接到字符串中
      let titles = '';
      for (let i = 0; i < this.text.length; i++) {
        titles = titles+i+'.'+this.text[i].title
      }
      //字符串占位px
      let width = this.getLenPx(titles, 16)
      this.timer = setInterval(() => {
        this.distance = this.distance - 1;
        // 如果位移超过文字宽度，则回到起点
        if (-this.distance >= width) {
          this.distance = this.getDistance()
        }
        this.$refs.box.style.transform = 'translateX(' + this.distance + 'px)';
      }, 15)

    },
    stopText() {
      //清除定时器
      clearInterval(this.timer)
      //清除定时器之后，需要重新将定时器置为null
      //this.timer = null
    },
    // 获取字符串占位px
    getLenPx(str, font_size) {
      var str_leng = str.replace(/[^\x00-\xff]/gi, 'aa').length;
      return str_leng * font_size / 2
    },
    //获取滚动框宽度
    getDistance(){
      return this.$refs.getWidth.offsetWidth
    },

    showNewsInfo(item) {
      this.newsInfo = item
      this.$refs['newsModal'].show()
    },

    updateReaderInf(){
      let newsId = this.newsInfo.news_id
      axios.post('/api/newsreader/save', {"newsId": newsId}).then(function (resp) {
        console.log(resp)
      })
    }
  },
  mounted() {
    this.distance = this.getDistance()
    this.getNews()
  },
  destroyed() {
    this.stopText()
  }
}
</script>

<style lang='scss' scoped>
.wrap {
  width: 100%;
  overflow: hidden;
}

.box {
  width: 8000%;
  overflow: hidden;
}
.box a{
  padding-right: 10px;
}
</style>
